import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import CaseStudyHeader from "../../../components/pages/work/case-studies/CaseStudyHeader"
import CaseStudyConsultation from "../../../components/pages/work/case-studies/CaseStudyConsultation"
import CaseStudyUserStories from "../../../components/pages/work/case-studies/CaseStudyUserStories"
import CaseStudyWireframes from "../../../components/pages/work/case-studies/CaseStudyWireframes"
import CaseStudyIterations from "../../../components/pages/work/case-studies/CaseStudyIterations"
import CaseStudyTechnologies from "../../../components/pages/work/case-studies/CaseStudyTechnologies"
import CaseStudyResults from "../../../components/pages/work/case-studies/CaseStudyResults"
import Contact from "../../../components/pages/index/Contact"
import CaseStudyChallenges from "../../../components/pages/work/case-studies/CaseStudyChallenges"

const GraphicColorPage = ({ pageContext, location }) => {
  const consultationDetails = {
    company: "Graphic Color",
    location: "Toronto, Canada",
    industry: "Stock Photos",
    developmentType: "Web Application",
    scope: "Design and Development",
    businessObjectives:
      "To provide a free design sharing platform where designers can share stock photos, vectors, illustrations and icons. Advertising will eventually be incorporated once user accounts reach 1000+.",
    desiredOutcomes:
      "To provide an easy to use platform for designers to share their art and receive tips from others. The target is to grow by 100 users per month so monetization can occur after the first year.",
    targetMarket: "Designers & Artists",
    competition: "Reddit",
    competitiveAdvantage:
      "Graphic Color will offer Collections where designers can group relative images allowing more of their designs and artwork to be used by others in a single download.",
    timeFrame: "6 Months",
  }

  const userStories = {
    mustHaves: [
      "A landing page with a search box and gallery that displays the most popular and recent art.",
      "Each resource must have a download page that displays the collection and related resources.",
      "A profile view to see the users bio and art",
      "A signup page that allows you to sign up with Facebook, Google, or email.",
      "Every user must have access to an upload page for their designs or artwork.",
      "Every design should have the option for multi-shots to view it from different angles and zoom.",
      "Social Media links should be visible on every page.",
      "Resource search box should be available on every page.",
      "Resource search box should have a dropdown to search specific categories.",
      "Color scheme should be neutral blue.",
      "Every user should have ability to receive tips.",
      "Every resource should have a comments section to discuss the design.",
    ],
    niceToHaves: [
      "Resources should have tags for easier categorization.",
      "Each resource should have a likes, favorited and shared counter.",
      "A user ranking system based on posts, and likes.",
      "Admin panel to manage users and resources.",
      "Adult content filter.",
      "License information available on every page.",
      "Instagram integration for users to share straight to Instagram.",
      "Infinite scroll on resources instead of pagination.",
      "Show online presence with color dot on profile images",
      "Multiple language support.",
    ],
    maybeLater: [
      "An API where developers can integrate resources into their projects",
      "Ability to DM through the user's profile.",
      "Tag grouping page to show the top tags and content.",
      "Design and artwork contests so users can win prizes from corporate sponsors.",
      "Option to upload videos",
      "Shutterstock ad integration",
      "Further advertising capabilities",
      "Infinite scroll on resources instead of pagination.",
    ],
  }

  const iterations = {
    iteration1: {
      stories: [
        "Design and development environment setup",
        "Asset collection and review",
        "Wireframe review",
      ],
      weeks: "Week 1",
    },
    iteration2: {
      stories: [
        "Landing page design and development",
        "Footer design and development",
        "Navbar design and development",
      ],
      weeks: "Week 2",
    },
    iteration3: {
      stories: [
        "Database development for file name, type, and tag",
        "Filesystem setup and folder creation",
        "Search algorithm development",
        "API endpoint development",
      ],
      weeks: "Week 3-4",
    },
    iteration4: {
      stories: [
        "Connect the landing page search box to the backend API",
        "Connect the landing page image placeholders to the backend API to return either popular or recent",
        "Design and develop search results page",
        "Design and develop navbar with integrated search box",
      ],
      weeks: "Week 4-5",
    },
    iteration5: {
      stories: [
        "Design and develop resource download page to show collections, related designs, author information, design description and a comments section.",
      ],
      weeks: "Week 6",
    },
    iteration6: {
      stories: [
        "Backend API development to enable download capabilities for design and collection",
        "Database development for commenting system",
        "Database development for like, dislike and share",
      ],
      weeks: "Week 7-8",
    },
    iteration7: {
      stories: [
        "Design and develop signup page for email, Facebook and Google.",
        "Backend API development for authentication system",
        "Database development for user accounts",
      ],
      weeks: "Week 9-10",
    },
    iteration8: {
      stories: [
        "Design and develop login page",
        "Design and develop profile page",
        "Database development for additional user data",
        "Backend API development to display all user designs and artwork.",
      ],
      weeks: "Week 11-12",
    },
    iteration9: {
      stories: [
        "Upload page design and development",
        "Backend API creation for uploads",
        "Database linkage across multiple collections",
        "Testing and safeguards put in place for uploads",
      ],
      weeks: "Week 13-14",
    },
    iteration10: {
      stories: [
        "Tag system integration for image categorization",
        "Allow users to suggest alternate tags for better search optimization",
      ],
      weeks: "Week 15-16",
    },
    iteration11: {
      stories: [
        "Admin area design and development",
        "Include options to add and remove users and designs.",
        "Include options to adjust tags and categories of designs",
      ],
      weeks: "Week 17-18",
    },
    iteration12: {
      stories: [
        "Tip system integration allowing users to tip via Paypal, credit card, and bitcoin.",
      ],
      weeks: "Week 19-20",
    },
    iteration13: {
      stories: [
        "Newsletter setup with GetResponse",
        "Enable all Social Media links",
        "Design and develop all legal pages",
      ],
      weeks: "Week 21-22",
    },
    iteration14: {
      stories: [
        "Release",
        "Emergency support standby as website is tested with live traffic",
      ],
      weeks: "Week 23",
    },
  }

  const technologies = {
    fullStack: true,
    icons: [
      "HTML5",
      "CSS3",
      "Sass",
      "Bootstrap",
      "JavaScript",
      "React",
      "Node",
      "MongoDB",
      "Jest",
      "Git",
      "Heroku",
    ],
  }

  const challenges = {
    challenge1: {
      challenge:
        "The Graphic Color Team needed help deciding whether to store their incoming resources in a database or filesystem.",
      win: (
        <>
          <p>
            It's common for the Tactic Apps team to help clients make important
            decisions on backend design. Graphic Color had a particularly
            important decision to make to support their potentially explosive
            growth.
          </p>
          <p>
            Tactic Apps helped weigh the pros and cons for storing resources in
            a database vs. storing files in the filesystem. This analysis
            included resource management, performance, and cost at scale.
          </p>
          <p className="mb-0">
            The Tactic Apps Team concluded that storing files in a properly
            managed filesystem would provide greater performance and cost
            significantly less as the Graphic Color resource base grows.
          </p>
        </>
      ),
    },
    challenge2: {
      challenge:
        "Agile Project Management was challenging since the backend development outweighed the front end.",
      win: (
        <>
          <p>
            Tactic Apps has always used Agile Project Management to show our
            customers results every 1-2 weeks. However, the Graphic Color
            application was largely a backend application while the frontend
            consisted of few pages with mostly dynamic content.
          </p>
          <p>
            We had to move away from end-to-end iterations and break the app
            into frontend and backend iterations. Since the visual frontend is
            what most clients are interested in, we could only should the
            Graphic Color team results every 1-3 weeks. 1 Week for frontend and
            2 weeks to build the backend to support the frontend.
          </p>
          <p className="mb-0">
            The Tactic Apps team covered this modified process in detail with
            the Graphic Color team before starting the project to set
            expectations. This allowed the project to run smoothly from the
            onset.
          </p>
        </>
      ),
    },
    challenge3: {
      challenge:
        "Graphic Color had 30 development intensive stories without enough time to build them all.",
      win: (
        <>
          <p>
            It's common for our clients to have a large list of stories that
            they'd like implemented for their application's release. While in
            some cases 30 stories can be completed in a 6 month time frame,
            Graphic Color's stories were all development intensive.
          </p>
          <p>
            Using Agile Project Management, Tactic Apps helped the Graphic Color
            team prioritize their stories and size them up relative to one
            another. We then broke them up into "Must Haves", "Nice to Haves",
            and "Maybe Later". This allowed us to scope the project out over the
            next 6 months to see what could be achieved.
          </p>
          <p className="mb-0">
            Being on the same page, the Tactic Apps team was able to build a
            core application to satisfy Graphic Color's targeted user base.
            Additional features are now slated for the next release.
          </p>
        </>
      ),
    },
    challenge4: {
      challenge:
        "Midway through the project, the Tactic Apps team realized that Graphic Color needed to tag resources.",
      win: (
        <>
          <p>
            Even though resource tagging was in the story list, it wasn't deemed
            to be a "Must Have" until we started designing the database for the
            stored resources. Unfortunately, resource tagging was a significant
            story and Graphic Color had to bend on either scope, timeline or
            budget.
          </p>
          <p className="mb-0">
            As in most cases, Tactic Apps recommended to be flexible on scope
            which meant that a "Must Have" of equal size had to move to the
            "Nice to Have" list. After some collaboration, we made the story
            swap so that timeline and budget would not be affected.
          </p>
        </>
      ),
    },
    challenge5: {
      challenge:
        "Heroku announced that mLab is shutting down its add-on which will essentially kill Graphic Color's database.",
      win: (
        <>
          <p>
            MongoDB acquired mLab which cause the mLab team to start migrating
            their users to MongoDB Atlas. This acquisition also triggered the
            end of the Heroku add-on which caused a ripple through the
            development community. Fortunately the Tactic Apps team caught this
            early.
          </p>
          <p className="mb-0">
            We were able to follow the migration guide and move Graphic Color's
            database to the new platform without any noticeable downtime.
          </p>
        </>
      ),
    },
  }

  const caseStudyData = {
    numBoxes: {
      developers: "2",
      hourly: "105",
      time: "6",
    },
  }

  const resultsImageNames = {
    results1: "Landing Page",
    results2: "Search Option Dropdown",
    results3: "Design Download",
    results4: "Design Comments",
    results5: "Profile Creation",
    results6: "Profile",
    results7: "Design Upload",
  }

  return (
    <StaticQuery
      query={graphql`
        {
          hero: file(
            relativePath: {
              eq: "work/web/graphic-color/graphic-color-hero.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          deviceDisplay: file(
            relativePath: {
              eq: "work/web/graphic-color/graphic-color-devices.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe1: file(
            relativePath: {
              eq: "work/web/graphic-color/wireframes/graphic-color_landing_page.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe2: file(
            relativePath: {
              eq: "work/web/graphic-color/wireframes/graphic-color_landing_page_dropdown.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe3: file(
            relativePath: {
              eq: "work/web/graphic-color/wireframes/graphic-color_design_download.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe4: file(
            relativePath: {
              eq: "work/web/graphic-color/wireframes/graphic-color_design_comments.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe5: file(
            relativePath: {
              eq: "work/web/graphic-color/wireframes/graphic-color_create_profile.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe6: file(
            relativePath: {
              eq: "work/web/graphic-color/wireframes/graphic-color_profile.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe7: file(
            relativePath: {
              eq: "work/web/graphic-color/wireframes/graphic-color_design_upload.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          results1: file(
            relativePath: { eq: "work/web/graphic-color/header.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results2: file(
            relativePath: { eq: "work/web/graphic-color/header-dropdown.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results3: file(
            relativePath: { eq: "work/web/graphic-color/asset-view.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results4: file(
            relativePath: {
              eq: "work/web/graphic-color/asset-view-comments.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results5: file(
            relativePath: { eq: "work/web/graphic-color/create-profile.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results6: file(
            relativePath: { eq: "work/web/graphic-color/profile.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results7: file(
            relativePath: { eq: "work/web/graphic-color/upload.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={(data) => (
        <Layout>
          <Seo
            title="Graphic Color Case Study"
            keywords={[`graphic color web app case study`]}
            description="Graphic Color is a free platform where designers can share stock photos, vectors, illustrations and icons. This is a large web application built with React, NodeJS and MongoDB."
          />
          <CaseStudyHeader
            image={data.hero.childImageSharp.gatsbyImageData}
            title="Web Application Case study"
            subTitle="Graphic Color"
            pageContext={pageContext}
            location={location}
            crumbLabel="Graphic Color"
          />
          <main>
            <CaseStudyConsultation
              consultationDetails={consultationDetails}
              deviceImage={data.deviceDisplay}
            />
            <CaseStudyUserStories
              userStories={userStories}
              companyName={consultationDetails.company}
            />
            <CaseStudyWireframes
              wireframeImages={data}
              companyName={consultationDetails.company}
            />
            <CaseStudyIterations
              iterationData={iterations}
              companyName={consultationDetails.company}
            />
            <CaseStudyTechnologies
              technologies={technologies}
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <CaseStudyChallenges
              challenges={challenges}
              companyName={consultationDetails.company}
            />
            <CaseStudyResults
              caseStudyData={caseStudyData}
              resultsImages={data}
              resultsImageNames={resultsImageNames}
              resultsImageHeight="400px"
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <Contact
              heading={
                <>
                  Let's build your{" "}
                  <span className="text-primary">
                    {consultationDetails.developmentType}
                  </span>{" "}
                  Together
                </>
              }
            />
          </main>
        </Layout>
      )}
    />
  )
}

export default GraphicColorPage
